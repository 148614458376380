
import './App.css';
import Hero from './components/Hero/Hero';
import Program from './components/Program/Program';
import Reason from './components/Reason/Reason';
import Plan from './components/Plans/Plan';
import Testimonial from './components/Testimonials/Testimonial';
import Join from './components/Join/Join';
import Footer from './components/Footer/Footer';
function App() {
  return (
    <div className="App">
      <Hero/>
      <Program/>
      <Reason/>
      <Plan/>
      <Testimonial/>
      <Join/>
      <Footer></Footer>
    </div>
  );
}

export default App;
